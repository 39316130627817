import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    translate: TranslateService
  ) {
    // set available languages
    translate.addLangs([
      'de',
      'en',
      'es',
      'fr',
      'hi',
      'id',
      'ja',
      'nl',
      'pt',
      'ru',
      'zh'
    ]);

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');

    // set language based on browser
    let browserLang = localStorage.getItem('lang');

    if (!browserLang) {
      browserLang = translate.getBrowserLang();
    }

    if (translate.langs.indexOf(browserLang) > -1) {
      translate.use(browserLang);
    }
  }

  ngOnInit() {
    // apply title
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => this.titleService.setTitle(`${event.title} | METS HQ`));
  }
}
