import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor() {}

  public validate(form: UntypedFormGroup, scrollTo = true): void {
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);
      control.markAsTouched({ onlySelf: true });
      control.updateValueAndValidity();
    });

    if (scrollTo) {
      this.scrollToError();
    }
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    setTimeout(() => {
      const firstElementWithError = document.querySelector('.is-invalid');
      this.scrollTo(firstElementWithError);
    });
  }

  focusOnLastInput(elementId: string): void {
    setTimeout(() => {
      const foundElements = document.querySelectorAll(`#${elementId} input, #${elementId} textarea`);
      const lastElement = foundElements[foundElements.length - 1] as HTMLElement;
      lastElement.focus();
    });
  }
}
