import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'externalLink'
})
export class ExternalLinkPipe implements PipeTransform {
  transform(url: string) {
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
      url = `http://${url}`;
    }

    return url;
  }
}
