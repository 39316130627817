import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmlFixes'
})
export class HtmlFixesPipe implements PipeTransform {
  transform(value: string) {
    return value.replace(new RegExp('<p><br></p>', 'g'), '');
  }
}
