import { Input, OnDestroy, OnInit } from '@angular/core';
import { Component, forwardRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

import { AddressModel } from '../../models/address.model';

export const ADDRESS_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AddressSearchComponent),
  multi: true,
};

const DEFAULT_PLACEHOLDER = 'Enter an address...';

@Component({
  selector: 'app-address-search',
  templateUrl: './address-search.component.html',
  providers: [ADDRESS_VALUE_ACCESSOR]
})
export class AddressSearchComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  @Input() placeholder = DEFAULT_PLACEHOLDER;

  value: any;
  disabled = false;
  onChange: any;
  onTouch: any;
  formControl = new UntypedFormControl('');
  subscription: Subscription;

  options = {
    types: [],
    componentRestrictions: { country: 'AU' }
  };

  ngOnInit() {
    this.subscription = this.formControl.valueChanges
      .subscribe((v) => {
        this.value = v;

        if (this.onChange) {
          this.onChange(this.value);
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  writeValue(obj: any): void {
    if (obj && obj.formattedAddress) {
      this.placeholder = obj.formattedAddress;
    } else {
      this.placeholder = DEFAULT_PLACEHOLDER;
    }

    this.value = obj;
    this.formControl.setValue(this.value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public handleAddressChange(place: Address) {
    this.value = { ...this.extractAddress(place) };
    this.formControl.setValue(this.value);

    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  private extractAddress(place: Address) {
    const address = new AddressModel();

    if (place.geometry) {
      address.latitude = place.geometry.location.lat();
      address.longitude = place.geometry.location.lng();
    }

    if (place.address_components) {
      address.formattedAddress = place.formatted_address;

      place.address_components.forEach(component => {
        if (component.types.indexOf('subpremise') > -1) {
          address.unitNumber = component.long_name;
        } else if (component.types.indexOf('street_number') > -1) {
          address.streetNumber = component.long_name;
        } else if (component.types.indexOf('route') > -1) {
          address.streetName = component.long_name;
        } else if (component.types.indexOf('locality') > -1) {
          address.suburb = component.long_name;
        } else if (component.types.indexOf('administrative_area_level_1') > -1) {
          address.state = component.short_name;
        } else if (component.types.indexOf('postal_code') > -1) {
          address.postcode = component.long_name;
        }
      });
    }

    return address;
  }
}
