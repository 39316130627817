export class AddressModel {
  public formattedAddress: string;
  public unitNumber: string;
  public streetNumber: string;
  public streetName: string;
  public suburb: string;
  public state: string;
  public postcode: string;
  public latitude: number;
  public longitude: number;
}
