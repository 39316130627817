import { Injectable } from '@angular/core';

import { Title, Meta } from '@angular/platform-browser';

export class SeoData {
  id: string;
  title: string;
  description: string;
  image?: string;
  cardLarge?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private title: Title,
    private meta: Meta
  ) { }

  public set(data: SeoData) {
    // website title
    this.title.setTitle(`${data.title} | METS HQ`);

    // facebook
    this.meta.updateTag({
      property: 'og:site_name',
      content: 'METS HQ'
    });

    this.meta.updateTag({
      property: 'og:type',
      content: 'website'
    });

    // only set if the ID and object are valid, otherwise it'll lead to a broken link
    if (data.id) {
      this.meta.updateTag({
        property: 'og:url',
        content: `https://metshq.com.au/profiles/${data.id}`
      });
    }

    this.meta.updateTag({
      property: 'og:title',
      content: data.title
    });

    this.meta.updateTag({
      property: 'og:description',
      content: data.description
    });

    if (data.image) {
      this.meta.updateTag({
        property: 'og:image',
        content: data.image
      });
    } else {
      this.meta.removeTag('property="og:image"');
    }

    // twitter
    this.meta.updateTag({
      name: 'twitter:title',
      content: data.title
    });

    this.meta.updateTag({
      name: 'twitter:description',
      content: data.description
    });

    if (data.image) {
      this.meta.updateTag({
        name: 'twitter:image',
        content: data.image
      });

      this.meta.updateTag({
        name: 'twitter:image:alt',
        content: data.title
      });

      if (data.cardLarge) {
        this.meta.updateTag({
          name: 'twitter:card',
          content: 'summary_large_image'
        });
      }
    } else {
      this.meta.removeTag('name="twitter:image"');
      this.meta.removeTag('name="twitter:image:alt"');
      this.meta.removeTag('name="twitter:card"');
    }
  }

  public reset() {
    this.meta.removeTag('property="og:site_name"');
    this.meta.removeTag('property="og:type"');
    this.meta.removeTag('property="og:url"');
    this.meta.removeTag('property="og:title"');
    this.meta.removeTag('property="og:description"');
    this.meta.removeTag('property="og:image"');
    this.meta.removeTag('name="twitter:title"');
    this.meta.removeTag('name="twitter:description"');
    this.meta.removeTag('name="twitter:image"');
    this.meta.removeTag('name="twitter:image:alt"');
    this.meta.removeTag('name="twitter:card"');
  }
}
