import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'firestoreDate'
})
export class FirestoreDatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) { }

  transform(timestamp: any, format?: string): string {
    if (!timestamp) {
      return null;
    }

    const date = timestamp.toDate();

    if (format === 'none') {
      return date;
    }

    if (timestamp.hasOwnProperty('seconds')) {
      return formatDate(date, format || 'medium', this.locale);
    }

    return 'Invalid date';
  }
}
