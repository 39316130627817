// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyC3FvsKkoAUfvzd3rUEGpOv8pm3Np-3ZHM',
    authDomain: 'mets-capability-tool.firebaseapp.com',
    databaseURL: 'https://mets-capability-tool.firebaseio.com',
    projectId: 'mets-capability-tool',
    storageBucket: 'mets-capability-tool.appspot.com',
    messagingSenderId: '915906318149',
    appId: '1:915906318149:web:b895f916f22dc7b774b82c',
    measurementId: 'G-PKB6MFH7BT'
  },
  mapbox: {
    apiKey: 'pk.eyJ1IjoibWV0cy1jYXBhYmlsaXR5LXRvb2wiLCJhIjoiY2tkd2szd2o4MGZ5dTJ6bnczc2NtZHNjbiJ9.buo4rjJsIXeb-WggYIGvfQ'
  },
  algolia: {
    applicationId: 'NR77KP0J76',
    apiKey: '365616ba6e8c66b13e1ee9891f1aaa31',
    indexes: {
      en: 'profiles_en',
      zh: 'profiles_zh',
      es: 'profiles_es',
      hi: 'profiles_hi',
      id: 'profiles_id',
      pt: 'profiles_pt',
      ru: 'profiles_ru',
      ja: 'profiles_ja',
      de: 'profiles_de',
      fr: 'profiles_fr',
      nl: 'profiles_nl'
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
