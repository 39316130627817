import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralSingular'
})
export class PluralSingularPipe implements PipeTransform {
  transform(val: number, singularText: string, pluralText: string = null, includeNumber: boolean = true): string {
    const pluralWord = pluralText ? pluralText : `${singularText}s`;
    const response = (val > 1 || val === 0) ? `${pluralWord}` : `${singularText}`;
    return includeNumber ? `${val} ${response}` : response;
  }
}
