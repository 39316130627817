import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit = 25, completeWords = false, ellipsis = '...') {
    const originalLimit = limit;

    if (!value) {
      return value;
    }

    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }

    if (limit < 0) {
      limit = originalLimit;
    }

    return value.length > limit ? value.substr(0, limit) + ellipsis : value;
  }
}
