import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { FontAwesomeModule, FaIconLibrary, FaConfig } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AngularStickyThingsModule } from '@w11k/angular-sticky-things';
import { TimeagoModule } from 'ngx-timeago';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { ClickOutsideModule } from 'ng-click-outside';

// components
import { AddressSearchComponent } from './components/address-search/address-search.component';
import { SimpleProgressChartComponent } from './simple-progress-chart/simple-progress-chart.component';

// pipes
import { TruncatePipe } from './pipes/truncate.pipe';
import { LanguageNamePipe } from './pipes/language-name.pipe';
import { FirestoreDatePipe } from './pipes/firestore-date.pipe';
import { ExternalLinkPipe } from './pipes/external-link';
import { HtmlFixesPipe } from './pipes/html-fixes.pipe';
import { PluralSingularPipe } from './pipes/plural-singular.pipe';

// services
import { FormService } from './services/form.service';
import { SeoService } from './services/seo.service';

// tslint:disable-next-line: max-line-length
// import {  } from '@fortawesome/pro-light-svg-icons';

// tslint:disable-next-line: max-line-length
import { faChevronRight as farChevronRight, faTachometerAlt as farTachometerAlt, faInfoCircle as farInfoCircle, faBook as farBook, faPhotoVideo as farPhotoVideo, faKey as farKey, faTruck as farTruck, faCertificate as farCertificate, faAward as farAward, faProjectDiagram as farProjectDiagram, faGraduationCap as farGraduationCap, faUserCircle as farUserCircle, faSignOutAlt as farSignOutAlt, faChevronLeft as farChevronLeft, faClone as farClone, faSignInAlt as farSignInAlt } from '@fortawesome/pro-regular-svg-icons';

// tslint:disable-next-line: max-line-length
import { faChevronLeft as fasChevronLeft, faChevronRight as fasChevronRight, faSpinner as fasSpinner, faEnvelope as fasEnvelope, faPhone as fasPhone, faGlobe as fasGlobe, faChevronUp as fasChevronUp, faChevronDown as fasChevronDown, faMapMarkerAlt as fasMapMarkerAlt, faAt as fasAt, faFileAlt as fasFileAlt, faPlay as fasPlay, faPhotoVideo as fasPhotoVideo, faSearch as fasSearch, faSort as fasSort, faPlus as fasPlus, faEdit as fasEdit, faCheck as fasCheck, faTimes as fasTimes, faFilePdf as fasFilePdf, faRedoAlt as fasRedoAlt, faGlobeAmericas as fasGlobeAmericas, faExternalLink as farExternalLink, faBars as fasBars, faColumns as fasColumns, faDownload as fasDownload } from '@fortawesome/pro-solid-svg-icons';

@NgModule({
  declarations: [
    AddressSearchComponent,
    TruncatePipe,
    LanguageNamePipe,
    SimpleProgressChartComponent,
    FirestoreDatePipe,
    ExternalLinkPipe,
    HtmlFixesPipe,
    PluralSingularPipe
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    FontAwesomeModule,
    GooglePlaceModule,
    AutocompleteLibModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxDropzoneModule,
    AngularStickyThingsModule,
    TimeagoModule.forRoot(),
    Nl2BrPipeModule,
    ClickOutsideModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    TranslateModule,
    AddressSearchComponent,
    ModalModule,
    TooltipModule,
    BsDropdownModule,
    TruncatePipe,
    NgxDropzoneModule,
    AngularStickyThingsModule,
    LanguageNamePipe,
    SimpleProgressChartComponent,
    FirestoreDatePipe,
    TimeagoModule,
    Nl2BrPipeModule,
    ExternalLinkPipe,
    HtmlFixesPipe,
    ClickOutsideModule,
    PluralSingularPipe
  ],
  providers: [
    FormService,
    SeoService,
    LanguageNamePipe
  ]
})
export class SharedModule {
  constructor(faConfig: FaConfig, library: FaIconLibrary) {
    // light
    // library.addIcons(
    //   falCheck,
    //   falTimes
    // );

    // regular
    library.addIcons(
      farChevronRight,
      farTachometerAlt,
      farInfoCircle,
      farBook,
      farPhotoVideo,
      farKey,
      farTruck,
      farCertificate,
      farAward,
      farProjectDiagram,
      farGraduationCap,
      farUserCircle,
      farSignOutAlt,
      farChevronLeft,
      farClone,
      farSignInAlt
    );

    // solid
    library.addIcons(
      fasChevronLeft,
      fasChevronRight,
      fasSpinner,
      fasTimes,
      fasEnvelope,
      fasPhone,
      fasGlobe,
      fasChevronUp,
      fasChevronDown,
      fasMapMarkerAlt,
      fasAt,
      fasFileAlt,
      fasPlay,
      fasPhotoVideo,
      fasSearch,
      fasSort,
      fasPlus,
      fasEdit,
      fasCheck,
      fasFilePdf,
      fasRedoAlt,
      fasGlobeAmericas,
      farExternalLink,
      fasBars,
      fasColumns,
      fasDownload
    );
  }
}
