import { Language } from '../../search/language-selector/language.model';

export const languages: Language[] = [
  {
    code: 'zh',
    name: 'Chinese',
    localName: '官话'
  },
  {
    code: 'es',
    name: 'Spanish',
    localName: 'español'
  },
  {
    code: 'en',
    name: 'English',
    localName: 'English'
  },
  {
    code: 'hi',
    name: 'Hindi',
    localName: 'हिन्दी'
  },
  {
    code: 'id',
    name: 'Indonesian',
    localName: 'bahasa Indonesia'
  },
  {
    code: 'pt',
    name: 'Portuguese',
    localName: 'português'
  },
  {
    code: 'ru',
    name: 'Russian',
    localName: 'русский язык'
  },
  {
    code: 'ja',
    name: 'Japanese',
    localName: '日本語'
  },
  {
    code: 'de',
    name: 'German',
    localName: 'Deutsch'
  },
  {
    code: 'fr',
    name: 'French',
    localName: 'français'
  },
  {
    code: 'nl',
    name: 'Dutch',
    localName: 'Nederlands'
  }
];
