
import { Pipe, PipeTransform } from '@angular/core';

import { languages } from '../constants/languages.constant';

@Pipe({
  name: 'languageName'
})
export class LanguageNamePipe implements PipeTransform {
  private allLanguages = languages;

  transform(code: string): string {
    const find = this.allLanguages.find(x => x.code === code);

    if (find) {
      return find.name;
    }

    return '';
  }
}
