import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-simple-progress-chart',
  templateUrl: './simple-progress-chart.component.html',
  styleUrls: ['./simple-progress-chart.component.scss']
})
export class SimpleProgressChartComponent implements OnChanges {
  @Input() total: number;
  @Input() complete: number;

  percentage = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (!this.complete || !this.total) {
      return;
    }

    this.percentage = (this.complete / this.total) * 100;
  }
}
